<template>
    <div>
        <img src="#" alt="" width="150px" />
        <p>The fastest way to custom your lovely crypto address!</p>
    </div>
</template>

<script>
    export default {};
</script>

<style lang="sass" scoped>
    @import "../css/variables"

    div
        margin-bottom: 1em
        color: $logo
        font-family: 'Montserrat', sans-serif
        text-align: center

        h1
            font-size: 3em
            font-weight: 500
            background: red
            width: 7.8em
            margin: 0 auto

        p
            font-size: 16px
            letter-spacing: 0px
            background: transparent !important
            font-weight: 500
            background: #000
            margin-top: 1em

    /*-- Responsive design --

    @media screen and (max-width: 1280px)
        h1
            font-size: 2.8em

    @media screen and (max-width: 1024px)
        div
            margin-bottom: 4em
            h1
                font-size: 2.5em
                border-width: 3px
            p
                font-size: 16px !important
                margin-top: 0.8em

    @media screen and (max-width: 640px)
        div
            margin-bottom: 0em
            h1
                font-size: 2.2em
            p
                font-size: 1.3em
                margin-top: 0.7em

    @media screen and (max-width: 480px)
        h1
            font-size: 2em
            border-width: 2px
        p
            font-size: 1.2em

    @media screen and (max-width: 320px)
        h1
            font-size: 1.6em
        p
            font-size: 1em
</style>
