<template>
    <div class="panel result">
        <div class="row">
            <div class="col">
                <div>
                <b>New Address:</b> <span class="output" v-text="address"></span></div> <br>
                <div>
                    <b>Private key:</b>
                    <span
                        class="output privatekey"
                        v-if="privateKey"
                        v-text="reveal ? privateKey : 'Click to Open'"
                        @click="revealKey()"
                    ></span>
                    <br><br>
                    <span style="font-size:13px;color:#ffc413">You can export this private key to metamask or trustwallet then your new address is ready to use!</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import * as blockies from 'blockies';

    export default {
        props: {
            address: String,
            privateKey: String,
        },
        data: function () {
            return {
                reveal: false,
            };
        },
        watch: {
            address(addr) {
                this.reveal = false;
                const id = document.getElementById('identicon');
                id.innerHTML = '';
                if (addr) {
                    id.appendChild(blockies({ seed: addr.toLocaleLowerCase(), scale: 6 }));
                }
            },
        },
        methods: {
            revealKey() {
                this.reveal = true;
            },
        },
    };
</script>

<style lang="sass" scoped>
    @import "../css/variables"
    #identicon
        width: 48px
        height: 48px
        margin: 0 15px
        background-color: $panel-background-alt

    .output
        font-family: $monospace-font
        color: $text-alt
        margin-left: 15px
        word-break: break-all
        font-size: 15px

    .panel > div:not(:last-child)
        margin-bottom: 15px

    .save
        margin-top: 30px

    @media screen and (min-width: 992px)
        .save
            margin-top: 0
</style>
