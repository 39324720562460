<template>
    <div class="panel">
        <h2 class="head-text">Use-Case</h2>
        <p> 
            Back Custom Address
            <span class="monospace">0x9a9e925cDF997dFF666F970<span class="text-red">AaAaA</span></span>
             <br /><br />
            Front Custom Address
            <span class="monospace">0x<span class="text-red">bAbAbA</span>9a9e925cDF997dFF666F970</span>
            
           
           
        </p>
        <!-- <div class="shortcut">
            <button type="button" class="button-large" @click="scrollDown">Start now</button>
        </div> -->
        <!-- <h2>Performance</h2>
        <p>
            For some reason, the performance of Vanity-ETH can vary a lot from a browser to another.
            Currently, Chrome provides the best results.<br>
            Using Vanity-ETH on your phone or tablet will work, but don't expect to reach the speed of a good old computer.
        </p>
        <h2>Compatibility</h2>
        <p>
            Any address generated with Vanity-ETH is ERC-20 compatible, which means you can use it for an ICO, an
            airdrop, or just to withdraw your funds from an exchange.<br>
            The keystore file is 100% compatible with MyEtherWallet, MetaMask, Mist, and geth.
        </p> -->
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                scrollTimeOut: null,
            };
        },
        methods: {
            scrollDown() {
                this.scrollTo(document.getElementById('input-panel'), -1);
            },
            scrollTo(element, lastValue) {
                let currentValue = window.scrollY;
                let diff = element.getBoundingClientRect().top / 6;
                if (Math.abs(diff) > 1 && currentValue > lastValue) {
                    window.scrollTo(0, window.scrollY + diff);
                    this.scrollTimeOut = setTimeout(this.scrollTo, 30, element, currentValue);
                } else if (currentValue >= lastValue) {
                    document.getElementById('input').focus();
                }
            },
        },
    };
</script>

<style lang="sass" scoped>
    @import "../css/variables"
    p
        margin: 15px 0 20px
        color: $text-alt
        overflow-x: hidden
        text-overflow: ellipsis
        .monospace
            font-family: $monospace-font
            font-size: 0.85em
    .shortcut
        text-align: center
        .button-large
            width: 150px
            margin: 15px 0 35px
</style>
