<script>
    export default {};
</script>

<style lang="sass" scoped>
    @import "../css/variables"

    a
        &:hover .octo-arm
            animation: octocat-wave 560ms ease-in-out
        svg, img
            position: absolute
            top: 0
            border: 0
            right: 0

    @keyframes octocat-wave
        0%, 100%
            transform: rotate(0)
        20%, 60%
            transform: rotate(-25deg)
        40%, 80%
            transform: rotate(10deg)

    @media (max-width: 500px)
        a:hover .octo-arm
            animation: none

        a .octo-arm
            animation: octocat-wave 560ms ease-in-out
</style>
